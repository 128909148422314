.hero-wrap{
    background-image: url('../../images/Pictures/sec-1.jpg');
    height: 500px;
    /* overflow: hidden; */
    background-size: cover;
    background-repeat: no-repeat; /* Do not repeat the image */

    /* width: 100vw; */
    min-width: 100%;
}
@media only screen and (max-width: 768px) {
    .hero-wrap{
        /* display:none; */
        background-image: url('../../images/Pictures/sec-1.jpg');
        height: 500px;
        /* overflow: hidden; */
        background-size: contain;
        background-repeat: no-repeat; /* Do not repeat the image */
    
        /* width: 100vw; */
        min-width: 100%;
    }
    .ftco-intro {
        margin-top: -150px!important;
    }
}
#img{
    background-image: url('../../images/cause-1.jpg');
    height: 10vh;
}

#img2{
    background-image: url('../../images/cause-2.jpg');
}

#img3{
    background-image: url('../../images/cause-3.jpg');
}

#img4{
    background-image: url('../../images/cause-4.jpg');
}

#img5{
    background-image: url('../../images/cause-5.jpg');
}

#img6{
    background-image: url('../../images/cause-6.jpg');
}

#person_1{
    background-image: url('../../images/image_2.jpg');
}

#person_2{
    background-image: url('../../images/cause-6.jpg');
}

#person_3{
    background-image: url('../../images/image_3.jpg');
}

#cause-2{
    background-image: url('../../images/cause-2.jpg');
}

#cause-3{
    background-image: url('../../images/cause-3.jpg');
}

#cause-4{
    background-image: url('../../images/cause-4.jpg');
}
#cause-5{
    background-image: url('../../images/cause-5.jpg');
}
#cause-6{
    background-image: url('../../images/cause-6.jpg');
}

#image_1{
    background-image: url('../../images/image_1.jpg');
}
#image_2{
    background-image: url('../../images/image_2.jpg');
}
#image_3{
    background-image: url('../../images/image_3.jpg');
}