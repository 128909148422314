#bg_13{
    background-image: url('../../../images/cause-6.jpg');
    width: 100%;
    background-size: contain;
}
.resp-to-a-call-about{
    padding: 64px 0px;
}
@media (max-width:768px){
    .resp-to-a-call-about{
        padding: 0px 0px 48px 0px;
    }   
}