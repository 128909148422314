#ftco-nav ul li a {
    font-size: 1.6rem;
}
/* #ftco-nav{
    background-color: cadetblue;
} */
.responsive-nav{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.responsive-nav ul li a:hover{
    background: #f86f2d;
    padding: 8px 16px;
    /* border: 1px solid black; */
    cursor: pointer;
    color: black;
    border-radius: 12px;
}
@media (max-width:768px) {
    .responsive-nav{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}